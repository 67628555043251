var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"scrumboard-x"},[_c('div',{staticClass:"scrumboard-col"},[_c('base-card',[_c('v-card-title',[_c('div',{staticClass:"d-flex justify-space-between"},[_vm._v(" File "),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"dark","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Action ")])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Another Action ")])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Something else ")])],1)],1)],1)],1)]),_c('draggable',_vm._b({staticClass:"list-group",attrs:{"list":_vm.list1,"ghost-class":"ghost","group":"people"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition","name":"flip-list"}},_vm._l((_vm.list1),function(element,index){return _c('base-card',{key:element.name,staticClass:"list-group-item sortable"},[_c('v-card-text',[_c('div',{staticClass:"d-flex mb-4"},[_c('v-icon',{staticClass:"text-15 success--text text--darken-1 mr-1"},[_vm._v(" mdi-check-decagram ")]),_c('p',{staticClass:"ma-0 success--text text--darken-1"},[_vm._v(" Completed ")])],1),_c('p',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(element.name)+" ")]),_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',{staticClass:"text-caption text--disabled font-weight-medium"},[_c('v-icon',{staticClass:"text-body-1"},[_vm._v("mdi-timer-outline")]),_vm._v(" 5/2/2020 ")],1),_c('v-avatar',[_c('v-img',{attrs:{"src":element.img,"alt":"John"}})],1)],1)])],1)}),1)],1)],1)],1),_c('div',{staticClass:"scrumboard-col"},[_c('base-card',[_c('v-card-title',[_c('div',{staticClass:"d-flex justify-space-between"},[_vm._v(" File Two "),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"dark","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Action ")])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Another Action ")])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Something else ")])],1)],1)],1)],1)]),_c('v-card-text',[_c('draggable',_vm._b({staticClass:"list-group",attrs:{"list":_vm.list2,"ghost-class":"ghost","group":"people"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition","name":"flip-list"}},_vm._l((_vm.list2),function(element,index){return _c('base-card',{key:element.name,staticClass:"list-group-item sortable"},[_c('v-card-text',[_c('div',{staticClass:"d-flex mb-4"},[_c('v-icon',{staticClass:"text-15 success--text text--darken-1 mr-1"},[_vm._v(" mdi-check-decagram ")]),_c('p',{staticClass:"ma-0 success--text text--darken-1"},[_vm._v(" Completed ")])],1),_c('p',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(element.name)+" ")]),_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',{staticClass:"text-caption text--disabled font-weight-medium"},[_c('v-icon',{staticClass:"text-body-1"},[_vm._v("mdi-timer-outline")]),_vm._v(" 5/2/2020 ")],1),_c('v-avatar',[_c('v-img',{attrs:{"src":element.img,"alt":"John"}})],1)],1)])],1)}),1)],1)],1)],1)],1),_c('div',{staticClass:"scrumboard-col"},[_c('base-card',[_c('v-card-title',[_c('div',{staticClass:"d-flex justify-space-between"},[_vm._v(" File Two "),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"dark","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Action ")])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Another Action ")])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Something else ")])],1)],1)],1)],1)]),_c('v-card-text',[_c('draggable',_vm._b({staticClass:"list-group",attrs:{"list":_vm.list2,"ghost-class":"ghost","group":"people"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition","name":"flip-list"}},_vm._l((_vm.list2),function(element,index){return _c('base-card',{key:element.name,staticClass:"list-group-item sortable"},[_c('v-card-text',[_c('div',{staticClass:"d-flex mb-4"},[_c('v-icon',{staticClass:"text-15 success--text text--darken-1 mr-1"},[_vm._v(" mdi-check-decagram ")]),_c('p',{staticClass:"ma-0 success--text text--darken-1"},[_vm._v(" Completed ")])],1),_c('p',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(element.name)+" ")]),_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',{staticClass:"text-caption text--disabled font-weight-medium"},[_c('v-icon',{staticClass:"text-body-1"},[_vm._v("mdi-timer-outline")]),_vm._v(" 5/2/2020 ")],1),_c('v-avatar',[_c('v-img',{attrs:{"src":element.img,"alt":"John"}})],1)],1)])],1)}),1)],1)],1)],1)],1),_c('div',{staticClass:"scrumboard-col"},[_c('base-card',[_c('v-card-title',[_c('div',{staticClass:"d-flex justify-space-between"},[_vm._v(" File Two "),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"dark","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Action ")])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Another Action ")])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Something else ")])],1)],1)],1)],1)]),_c('v-card-text',[_c('draggable',_vm._b({staticClass:"list-group",attrs:{"list":_vm.list2,"ghost-class":"ghost","group":"people"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition","name":"flip-list"}},_vm._l((_vm.list2),function(element,index){return _c('base-card',{key:element.name,staticClass:"list-group-item sortable"},[_c('v-card-text',[_c('div',{staticClass:"d-flex mb-4"},[_c('v-icon',{staticClass:"text-15 success--text text--darken-1 mr-1"},[_vm._v(" mdi-check-decagram ")]),_c('p',{staticClass:"ma-0 success--text text--darken-1"},[_vm._v(" Completed ")])],1),_c('p',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(element.name)+" ")]),_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',{staticClass:"text-caption text--disabled font-weight-medium"},[_c('v-icon',{staticClass:"text-body-1"},[_vm._v("mdi-timer-outline")]),_vm._v(" 5/2/2020 ")],1),_c('v-avatar',[_c('v-img',{attrs:{"src":element.img,"alt":"John"}})],1)],1)])],1)}),1)],1)],1)],1)],1),_c('div',{staticClass:"scrumboard-col"},[_c('base-card',[_c('v-card-title',[_c('div',{staticClass:"d-flex justify-space-between"},[_vm._v(" File Two "),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"dark","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Action ")])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Another Action ")])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Something else ")])],1)],1)],1)],1)]),_c('v-card-text',[_c('draggable',_vm._b({staticClass:"list-group",attrs:{"list":_vm.list2,"ghost-class":"ghost","group":"people"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition","name":"flip-list"}},_vm._l((_vm.list2),function(element,index){return _c('base-card',{key:element.name,staticClass:"list-group-item sortable"},[_c('v-card-text',[_c('div',{staticClass:"d-flex mb-4"},[_c('v-icon',{staticClass:"text-15 success--text text--darken-1 mr-1"},[_vm._v(" mdi-check-decagram ")]),_c('p',{staticClass:"ma-0 success--text text--darken-1"},[_vm._v(" Completed ")])],1),_c('p',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(element.name)+" ")]),_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',{staticClass:"text-caption text--disabled font-weight-medium"},[_c('v-icon',{staticClass:"text-body-1"},[_vm._v("mdi-timer-outline")]),_vm._v(" 5/2/2020 ")],1),_c('v-avatar',[_c('v-img',{attrs:{"src":element.img,"alt":"John"}})],1)],1)])],1)}),1)],1)],1)],1)],1),_c('div',{staticClass:"scrumboard-col"},[_c('base-card',[_c('v-card-text',[_c('v-btn',{attrs:{"block":"","color":"primary","large":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Add Column ")],1)],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }